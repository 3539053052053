import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import AboutMe from '../../components/AboutMe/AboutMe'
import Questions from '../../components/Questions/Questions'
import Structure from '../../components/Structure/Structure'
import VideoClip from '../../components/VideoClip/VideoClip'
import Advantages from '../../components/Advantages/Advantages'
import Callback from '../../components/Callback/Callback'
import Map from '../../components/Map/Map'
import Feedback from '../../components/Feedback/Feedback'
import VideoСollection from '../../components/VideoСollection/VideoСollection'

const Main = ({isOpen, popupOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <AboutMe/>
            <Feedback/>
            <Advantages
                isOpen={isOpen}
            />
            <Structure/>
            <VideoСollection/>
            <VideoClip/>
            <Questions/>
            <Callback
                popupOpen={popupOpen}
            />
            <Map/>
        </section>
    )
}

export default Main
import React from 'react'
import styles from './Structure.module.scss'
import { motion } from "framer-motion"
import image from '../../image/Structure/img.png'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .7 }
    }
}

const textAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const Structure = () => {

    return (
        <motion.section 
        className={styles.main}
        id='sostav' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
                <motion.h2 
                    className={styles.title}
                    variants={textAnimation}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >СОСТАВ КОМПЛЕКСА <span>ALFA&#160;NEСTAR&#160;FORTE</span></motion.h2>
                <motion.h3 
                    className={styles.subtitle}
                    variants={textAnimation}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >содержание витаминов, макро и микро элементов</motion.h3>
                <div className={styles.box}>
                    <motion.div 
                        className={styles.info}
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <ul className={styles.list}>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>глюкозы, г/100 см&#179; - &#60; 10</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>фруктозы, г/100 см&#179; - &#60; 10</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>сахарозы, г/100 см&#179; - &#60; 0.4</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>янтарной кислоты, мг/100 см&#179; - 46</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>полифенолов, мг/100 см&#179; - 20.6</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>витамина В3, мг/100 см&#179; - 9.48</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>витамина В2, мкг/100 см&#179; - 14</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>витамина В5, мкг/100 см&#179; - 420</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>витамина В6, мкг/100 см&#179; - 20</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>витамина Е, мкг/100 см&#179; - 219</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>витамина С, мкг/100 см&#179; - 2490</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>железа, мкг/100 дм&#179; - 4030</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>кальция, мкг/100 дм&#179; - 314</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>марганца, мкг/100 дм&#179; - 2410</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>магния, мкг/100 дм&#179; - 74</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>калия, мкг/100 дм&#179; - 779</span></p>
                            </li>
                            <li className={styles.item}>
                                <p className={styles.check}>&#10003;</p>
                                <p className={styles.text}>Массовая концентрация <span>селена, мкг/100 дм&#179; - 226</span></p>
                            </li>
                        </ul>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='человек' variants={imageAnimation}/>
                </div>
            </div>   
    </motion.section>
    )
}

export default Structure
import React from 'react'
import { motion } from "framer-motion"
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img_1 from '../../image/Feedback/img_1.jpg'
import img_2 from '../../image/Feedback/img_2.jpg'
import img_3 from '../../image/Feedback/img_3.jpg'
import img_4 from '../../image/Feedback/img_4.jpg'
import img_5 from '../../image/Feedback/img_5.jpg'
import img_6 from '../../image/Feedback/img_6.jpg'
import img_7 from '../../image/Feedback/img_7.jpg'
import img_8 from '../../image/Feedback/img_8.jpg'
import img_9 from '../../image/Feedback/img_9.jpg'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const Feedback = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.7,
                                        spaceBetween: 5,
                                    },

                                    450: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_1} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_2} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_3} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_4} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_5} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>           
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_6} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_7} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_8} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <img className={styles.image} src={img_9} alt = 'звездочки'/>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </motion.div>
        </motion.section>
    )
}

export default Feedback
import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>ДОСТОИНСТВА КОМПЛЕКСА <span>ALFA&#160;NEСTAR&#160;FORTE</span></motion.h2>
        <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>01</p>
                            <p className={styles.item_title}>Высокая степень ионизации</p>
                        </div>
                        <p className={styles.item_text}>Благодаря высокой ионизации ALFA NEСTAR FORTE имеет <span> высокий отрицательный окислительно-восстановительный потенциал.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>02</p>
                            <p className={styles.item_title}>Высокая восстановительная способность</p>
                        </div>
                        <p className={styles.item_text}>Восстановительная сила комплекса ALFA NEСTAR FORTE <span> действует на наши клетки и обновляет их.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>03</p>
                            <p className={styles.item_title}>Очистка организма</p>
                        </div>
                        <p className={styles.item_text}>За счет высокого окислительно-восстановительного потенциала, ALFA NEСTAR FORTE <span> нейтрализует омертвевшие клетки, продукты обмена веществ, ксенобиотики и способствует выводу их из организма.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>04</p>
                            <p className={styles.item_title}>Нейтрализация кислоты</p>
                        </div>
                        <p className={styles.item_text}>ALFA NEСTAR FORTE поставляет организму много электронов. Они немедленно вступают в реакцию с протонами (валентностями кислот) в клетке и нейтрализуют их. <span>Клетки снова получают все необходимые питательные вещества и оптимально защищаются от атак изнутри и снаружи.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>05</p>
                            <p className={styles.item_title}>Защита от свободных радикалов</p>
                        </div>
                        <p className={styles.item_text}>ALFA NEСTAR FORTE является сильным натуральным антиоксидантом, поэтому он  <span>защищает наши клетки, прежде всего клеточные ядра, от воздействия свободных радикалов.</span> </p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>06</p>
                            <p className={styles.item_title}>Способствует нормализации метаболизма</p>
                        </div>
                        <p className={styles.item_text}>Способствует нормализации метаболизма, начиная с клеточного уровня, - <span>восстанавливает обмен веществ, улучшает иммунитет, выносливость и укрепляет здоровье.</span></p>
                    </motion.li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Advantages
import React from 'react'
import header from './HeaderContact.module.scss'

const HeaderContact = ({isOpen}) => {

    return (
        <div className={header.contacts}>
            <div className={header.call}>
                <div className={header.call_pulse}></div>
                <p className={header.call_text}>Звоните, ПН-ПТ 10:00-20:00</p>
            </div>
            <div className={header.box_phone}>
                <a className={header.phone}  href="tel:88007077306" target='_blank' rel='noopener noreferrer'>8 800 707 73 06</a>
            </div>
            <p className={header.text} onClick={isOpen}>заказать обратный звонок</p>
        </div>
    );
};

export default HeaderContact
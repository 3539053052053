import React from 'react'
import styles from './VideoСollection.module.scss'
import { motion } from "framer-motion"
import ReactPlayer from 'react-player'
import video from '../../image/VideoСollection/img_1.jpg'
import play from '../../image/VideoСollection/play.svg'
import myVideo from '../../video/promo.mp4'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const VideoСollection = () => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.div className={styles.item} variants={boxAnimation}>
            <ReactPlayer
                light = {video}
                controls={true}
                playing={true}
                width='100%'
                height='100%'
                className={styles.video} 
                url={myVideo}
                playIcon={<button className={styles.play}>
                    <span className={styles.pulse}></span>
                    <span className={styles.pulse_two}></span>
                    <img className={styles.image} src={play} alt = 'плей'/>
                </button>}
            />
        </motion.div>
        </div>
    </motion.section>
    )
}

export default VideoСollection
import React from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.svg'
import HeaderContact from '../HeaderContact/HeaderContact'
import HeaderButton from '../HeaderButton/HeaderButton'

const Header = ({isOpen}) => {

    return (
        <header className={header.header}>
        <div className={header.box}>
            <a className={header.logo_link} href='initial'>
                <img className={header.logo} src={logo} alt = 'логотип'/>
            </a>
            <HeaderButton/>
            <HeaderContact
                isOpen={isOpen}
            />    
        </div>
    </header>    
    );
};

export default Header
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion"
import emailjs from 'emailjs-com'
import styles from './Callback.module.scss'
import image from '../../image/Callback/image.png'
import disactive from '../../image/Callback/check.svg'
import ButtonBox from '../../components/ButtonBox/ButtonBox'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"
import wp from '../../image/InitialPage/touch.svg'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const Callback = ({popupOpen}) => {
    
    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_fy7ffcd', 'template_fqbt6rn', form.current, 'SfhAIn8v3kixxE1Sd')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, popupOpen] )


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.overlay}>
            <motion.div className={styles.box} variants={boxAnimation}>
                <div className={styles.box_text}>
                    <h2 className={styles.title}>ОСТАЛИСЬ ВОПРОСЫ?</h2>
                    <h3 className={styles.subtitle}>получить консультацию специалиста прямо сейчас</h3>
                    <form className={styles.form} onSubmit={sendData} ref={form}>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите имя
                                    <input
                                        className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                        type='text' 
                                        name='name'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'  
                                        required/>
                                </label>
                            </div>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите телефон      
                                    <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        mask={'+7\\(999) 999-99-99'}
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                                    <button className={`${styles.button} ${buttonDisabled && styles.button_disabled}`}type='submit'>получить консультацию <span className={styles.glare}></span> <img src={wp} alt="Whatsapp"/></button>
                        </form>
                    <div className={styles.box_item}>
                        <div className={styles.icon_boxs}>
                            <img className={styles.icon} src={disactive} alt='галочка'/>
                        </div>
                        <div className={styles.box_text}>
                            <p className={styles.text}>Cогласен с условиями политики конфиденциальности данных</p>
                        </div>
                    </div>
                    <div className={styles.box_social}>
                        <p className={styles.text_social}>или напишите, мы онлайн</p>
                        <ButtonBox/>
                    </div>           
                </div>
                <div className={styles.box_image}>
                    <img className={styles.image} src={image} alt='мальчик'/>
                </div>
            </motion.div>
            </div>
        </motion.section>
    )
}

export default Callback
import React from 'react'
import styles from './CardVideo.module.scss'
import ReactPlayer from 'react-player'
import play from '../../image/CardVideo/play.svg'


const CardVideo = ({item}) => {

    return (
        <li className={styles.item}>
        <ReactPlayer 
                light={true}
                controls={true}
                playing={true}
                width='100%'
                height='100%'
                className={styles.video} 
                url={item.video}
                playIcon={<button className={styles.play}>
                    <span className={styles.pulse}></span>
                    <span className={styles.pulse_two}></span>
                    <img className={styles.image} src={play} alt = 'плей'/>
                </button>}
                
            />
        </li>
    )
}

export default CardVideo  

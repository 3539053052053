import React from 'react'
import styles from './Map.module.scss'
import { motion } from "framer-motion"
import DetailMap from '../DetailMap/DetailMap'
import phone from '../../image/Map/phone.svg'
import email from '../../image/Map/email-icon.svg'
import address from '../../image/Map/address.svg'
import time from '../../image/Map/time.svg'
import company from '../../image/Map/company.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Map = ({isLicenseOpen}) => {


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='kontakty'
            >
            <div className={styles.container}>
                <motion.div className={styles.box}>
                    <div className={styles.info}>
                        <motion.h2 className={styles.title} variants={titleAnimation}>Контакты</motion.h2>
                        <motion.div 
                            className={styles.box_contacts}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                                <a className={styles.link_phone}  href="tel:88007077306" target='_blank' rel='noopener noreferrer'>8 800 707 73 06</a>
                        </motion.div>
                        <motion.div 
                            className={styles.box_contacts}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                                <a className={styles.link_phone}  href="tel:+74951250306" target='_blank' rel='noopener noreferrer'>+7 (495) 125-03-06</a>
                        </motion.div>
                        <motion.div 
                            className={styles.box_contacts}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                                <a className={styles.link_phone}  href="tel:+79993444353" target='_blank' rel='noopener noreferrer'>+7 (999) 344-43-53</a>
                        </motion.div>
                        <motion.div 
                            className={styles.box_contacts}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                                <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                                <a className={styles.link_email}  href="mailto:alfanectarforte@gmail.com" target='_blank' rel='noopener noreferrer'>alfanectarforte@gmail.com</a>
                        </motion.div>
                        <motion.div 
                            className={styles.box_contacts}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                                <img className={styles.icon_contacts} src={company} alt = 'icon'/>
                                <p className={styles.text}>ООО "Панацея", ИНН&#160;7707365760</p>
                        </motion.div>
                        <motion.div 
                            className={styles.box_contacts}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                                <img className={styles.icon_contacts} src={address} alt = 'icon'/>
                                <p className={styles.text}>125057, г.Москва, Ленинградский проспект, д.57, офис 6</p>
                        </motion.div>
                        <motion.div 
                            className={styles.box_contacts_social}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                                <img className={styles.icon_contacts} src={time} alt = 'icon'/>
                                <div className={styles.box_time}>
                                    <p className={styles.text}>Пн-Пт 10:00 - 20:00</p>
                                </div>
                        </motion.div>
                    </div>
                    <motion.div 
                        className={styles.box_map}
                        variants={titleAnimation} 
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <DetailMap/>
                    </motion.div>
                </motion.div>
            </div>
        </motion.section>
    )
}

export default Map
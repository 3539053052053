import React from 'react'
import styles from './InitialPage.module.scss'
import wp from '../../image/InitialPage/touch.svg'


const InitialPage = ({isOpen}) => {
    return (
        <section className={styles.main}>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <h1 className={styles.title}>ALFA NEСTAR FORTE</h1>
                    <p className={styles.text}>Многофункциональный витаминно-минеральный комплекс на основе натуральных растительных компонентов</p>
                    <div className={styles.box_button}>
                        <button className={styles.button} type='button' onClick={isOpen}>получить консультацию <span className={styles.glare}></span> <img src={wp} alt="Whatsapp"/></button>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage
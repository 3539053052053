import React from 'react'
import { motion } from "framer-motion"
import styles from './Questions.module.scss'
import Hint from '../../components/Hint/Hint'
import { arrQuestions } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const boxAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


const Questions = () => {

    return (
        <motion.section
            id='voprosy-i-otvety'
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                    <div className={styles.box}>
                        <div className={styles.container}>
                            <motion.h2 className={styles.title} variants={titleAnimation}>ВОПРОС-ОТВЕТ</motion.h2>
                            <motion.p className={styles.subtitle} variants={titleAnimation}>самые популярные вопросы о ALFA&#160;NEСTAR&#160;FORTE</motion.p>
                            <motion.ul className={styles.list} variants={boxAnimation}>
                                    {arrQuestions.map(item  => {
                                        const keyUid = uuidv4();
                                        return(
                                            <Hint
                                                question={item.question}
                                                answer={item.answer}
                                                key={keyUid}
                                        />
                                        )
                                    }   
                                        )}
                            </motion.ul>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79993444353' aria-label='связаться вацап'>
                                <span>Задать свой вопрос в Whatsapp</span>
                                <svg  xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.92327 0.277344C4.59796 0.277344 0.2771 4.5982 0.2771 9.92351C0.2771 12.0559 0.967314 14.0199 2.13451 15.6192L0.905588 19.2554L4.73824 18.0602C6.23651 19.0141 8.01536 19.5697 9.92327 19.5697C15.2486 19.5697 19.5694 15.2488 19.5694 9.92351C19.5694 4.5982 15.2486 0.277344 9.92327 0.277344ZM14.3395 15.0973C12.5887 15.7988 10.5013 15.1198 8.21737 13.3858C5.93349 11.6519 4.42961 9.21085 4.3286 7.39834C4.2276 5.58582 5.70342 4.67115 6.50587 4.67676C7.30831 4.68237 8.18932 7.24683 8.20054 7.63963C8.21176 8.03805 7.21853 8.7451 7.17925 9.08179C7.13997 9.41848 8.53162 11.0795 9.12083 11.5284C9.70442 11.9717 10.9109 12.8247 11.5338 12.7461C12.151 12.6619 12.5607 11.4218 12.9535 11.3432C13.3463 11.2647 15.4899 12.3308 15.7031 12.6058C15.9164 12.8864 15.8939 14.4801 14.3395 15.0973Z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
        </motion.section>
        
    )
}

export default Questions
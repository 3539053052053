import React from 'react'
import styles from './AboutMe.module.scss'
import { motion } from "framer-motion"
import image from '../../image/AboutMe/img_2.png'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .7 }
    }
}

const textAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const AboutMe = () => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='человек' variants={imageAnimation}/>
                <div className={styles.info}>
                    <motion.h2 
                        className={styles.title} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        
                        >О КОМПЛЕКСЕ <span>ALFA&#160;NEСTAR&#160;FORTE</span></motion.h2>
                    <motion.h3 
                        className={styles.subtitle} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >Сильнейшие защитные свойства природных ингредиентов, собранные в уникальный комплекс.</motion.h3>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >ALFA NEСTAR FORTE – многофункциональный витаминно-минеральный комплекс на основе натуральных растительных компонентов — настоящий «эликсир молодости» с антиоксидантным, противовоспалительным и иммуномодулирующим действием. Выпускается в виде жидкости рубинового цвета, со стойким кисловато-терпким вкусом во флаконах емкостью по 20 мл, объем которого составляет разовую дозу приема.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >В состав субстанции входят экстракты ягод черноплодной рябины и черной смородины, эфирные масла, фруктоза, полифенол, витамин С, витамины группы В и Е, железо, кальций, магний, марганец, калий, селен, йод и другие высокоактивные компоненты, необходимые организму.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >ALFA NEСTAR FORTE восстанавливает окислительно-восстановительный потенциал, нормализует клеточный метаболизм, способствует нормализации уровня мочевой кислоты, восстанавливает ламинарный кровоток в органах малого таза, нижних конечностей, улучшая микроциркуляцию и реологические свойства крови, обладает анальгезирующим действием. Это мощный антиоксидант, обладающий регенерирующим воздействием на все жизненно-важные органы человека на клеточном уровне.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Применение препарата обеспечивает усиление иммунных свойств клеток организма, формирование защитно-компенсаторных механизмов, а также индукцию, активацию ферментативных процессов, с помощью которых восстанавливаются органы, поврежденные в результате воздействия физических, химических, биологических факторов, в том числе в результате естественных процессов старения организма.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Проведенные исследования и практика применения ALFA NEСTAR FORTE в терапевтической дозировке показывают значительное улучшение метаболических процессов и поддержание на нужном уровне гомеостаза в организме, предупреждает возникновение различных заболеваний, вызванных окислительными процессами жизнедеятельности и оказывающими токсическое влияние на клетки.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >В составе комплексной терапии ALFA NEСTAR FORTE показан при заболеваниях сердечно-сосудистой системы, ишемической болезни сердца, атеросклерозе и его осложнениях, артериальной гипертензии. Клинически доказана эффективность препарата при коррекции железодефицитных состояний в различные периоды жизни. При гипоиммунных состояниях, за счет повышения фагоцитарной активности макрофагов, числа гранулоцитов в костном мозге и плазме крови, увеличении количества иммуноглобулинов, выравнивания функции вилочковой железы. В составе комплексной терапии препарат хорошо зарекомендовал при лечении подагры за счет интенсивного снижения уровня мочевой кислоты в организме до физиологических показателей.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >ALFA NEСTAR FORTE благоприятствует нормализации липидного обмена, снижению уровня холестерина в крови, усвоению ненасыщенных жирных кислот. Это приводит к ускорению обмена веществ и регенерации тканей, быстрому исчезновению гематом и отеков, укреплению стенок сосудов.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Эффективен в профилактике и лечении астенического синдрома и синдрома хронической усталости, при интоксикации, алкогольной абстиненции (выводит из организма свободные радикалы и радионуклеиды), используется в комплексных программах лечения гипоксических состояний любого происхождения. Применение препарата значительно снижает общий уровень слабости, раздражительности у пациентов, происходит восстановление физиологических параметров сна.</motion.p>
                </div>
            </div>   
    </motion.section>
    )
}

export default AboutMe